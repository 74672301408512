import React from 'react'
import { Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';
import { RouterButtonProps } from '../../types'

const ToggleRouterButton = (props: RouterButtonProps) => {
  const styles = {
    backgroundColor: props.disabled ? '#F2F2F2' : '#FFFFFF',
    boxShadow: !props.disabled && '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: props.disabled ? '0px' : '4px',
    color: '#000000',
    width: 'wrap-content',
    height: '40px',
    textTransform: 'none',
    letterSpacing: 1,
    fontSize: '12px',
    ':hover': {
      backgroundColor: '#DDDDDD',
      color: '#000000',
      boxShadow: !props.disabled ? 'noce' : '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: !props.disabled ? '0px' : '4px',
    },
    '&.Mui-disabled': {
      color: '#000000',
    }
  }

  return (<Button sx={styles} variant='text' component={RouterLink} to={props.to}>{props.label}</Button>)
}

export default ToggleRouterButton