import React from 'react'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const DeleteCol = (props: any) => {
  const { rowId, action } = props;
  if (action === undefined) {
    return <DeleteOutlinedIcon onClick={() => alert('delete action has not been defined for this list')}/>
  }
  return <DeleteOutlinedIcon onClick={() => action(rowId)}/>
}

export default DeleteCol