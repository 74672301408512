import React from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import DeleteCol from './DeleteCol';
import EditCol from './EditCol';
import HideCol from './HideCol';
import ReorderCol from './ReorderCol';

import { FlexRowProps, ListRowProps} from './../../types'

const StyledFlexRow = styled('div')<FlexRowProps>(({small}) => ({
  display: 'flex',
  margin: +small === 1 ? '12px 0 12px 0' : '8px 0 8px 0'
}))

const RowSpacer = styled('div')({
  backgroundImage: 'url(/tablerowspacer.svg)',
  height: '1px',
  width: '100%'
})

interface DragItem {
  index: number
  id: string
  type: string
  props: any
}

function renderColumn(col, row, rowKey, drag: any, deleteAction?: (idToRemove: string) => Promise<void>, hideAction?: (idToRemove: string, newVisibleValue: boolean) => Promise<void>) {
  const Col = col.component
  switch(col.type) {
    case 'text':
      return (<Col key={rowKey + col['key']} style={col.style}>{row[col['key']]}</Col>)
    case 'flag':
      return (<Col key={rowKey + col['key']}>
        <ReactCountryFlag
          countryCode={row[col['key']]}
          svg
          style={{
            width: '20px',
            height: '14px',
        }}
      /></Col>)
    case 'image':
      return (<Col key={rowKey + col['key']} style={col.style}>
        <Box
        component="img"
        sx={{
          height: 100,
          width: 100,
          objectFit: 'cover',
          maxHeight: { xs: 54, md: 100 },
          maxWidth: { xs: 54, md: 100 },

        }}
        alt="thumb"
        src={row[col['key']]}
      /></Col>)
      case 'edit':
        return <Col key={rowKey + col['key'] + 'edit'} style={col.style}><EditCol to={`${row[col['key']]}`}/></Col>
      case 'hide':
        return <Col key={rowKey + col['key'] + 'hide'} style={col.style}><HideCol rowId={String(rowKey)} action={hideAction} state={row[col['key']]}/></Col>
      case 'delete':
        return <Col key={rowKey + col['key'] + 'delete'} style={col.style}><DeleteCol rowId={String(rowKey)} action={deleteAction}/></Col>
      case 'reorder':
        return <Col ref={drag} key={rowKey + col['key'] + 'reorder'} style={col.style}><ReorderCol/></Col>
    default:
      return (<Col style={col.style}>{row[col['key']]}</Col>)
  }
}

const ListRow = (props: ListRowProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const { index } = props;
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'any',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current || props.index === undefined || props.moveRow === undefined || props.dropRow === undefined) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      props.moveRow(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop: (item, monitor) => {
      props.dropRow(item.index);
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'any',
    item: () => {
      return { props, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
  <div ref={preview} style={{ opacity }} data-handler-id={handlerId}>
    <StyledFlexRow small={+!!props.small}>
      {props.columns.map((col) => {
        return renderColumn(col, props.row, props.keyColumnName, ref, props.deleteAction, props.hideAction)
      })}
    </StyledFlexRow>
    <RowSpacer/>
  </div>
  )
}

export default ListRow;
