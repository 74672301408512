import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../components/Form/ActionButton';
import PageHeader from '../components/Layout/PageHeader';
import TopBar from '../components/Layout/TopBar';
import { styled } from '@mui/system';
import FormTextField from '../components/Form/FormTextField';
import { Controller, useForm } from 'react-hook-form';
import { ArtworkFormInput, ImageUploadInput, StoryListDropdownData} from '../types';
import CancelButton from '../components/Form/CancelButton';
import { Stack } from '@mui/material';
import RadioFieldHorizontal from '../components/Form/RadioFieldHorizontal';
import RadioFieldVertical from '../components/Form/RadioFieldVertical';
import FormDatePicker from '../components/Form/FormDatePicker';
import SelectModal from '../components/Form/SelectModal';
import StoryChoiceListRow from '../components/Form/StoryChoiceListRow';
import ImageUploadField from '../components/Form/ImageUploadField/ImageUploadField';
import { ArtworkAPI } from '../api/ArtworkAPI';
import { ImageAPI } from '../api/ImageAPI';
import { StoryAPI } from '../api/StoryAPI';
import FormCheckboxField from '../components/Form/FormCheckboxField';

const FormHolder = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Nunito Sans',
  fontSize: 12
})

export default () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue
  } = useForm<ArtworkFormInput>({defaultValues: {hasStory: 'false', page: 'recent', storyId: null}});

  const [initialFormData , setInitialFormData] = React.useState<ArtworkFormInput | undefined>();
  const [storyListChoices , setStoryListChoices] = React.useState<StoryListDropdownData[]>([]);
  const [selectedStory, setSelectedStory] = React.useState<string | null>(null);
  const [selectedStoryTitle, setSelectedStoryTitle] = React.useState<string>('');
  const [selectedImage, setSelectedImage] = React.useState<string | null>();
  
  const { artworkId } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]); 

  async function getAssignableStories() {
    await StoryAPI.getAssignable()
    .then((response) => setStoryListChoices(response))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }

  async function getArtwork() {
    if (artworkId) {
      await ArtworkAPI.getOne(artworkId)
      .then((response) => {
        setInitialFormData(response)
        setSelectedStory(response['storyId'] || '')
        setSelectedStoryTitle(response.storyTitle || '')
      })
      .catch((e) => {
        //todo error handling
        if (e.response.status === 404) {
          navigate('/cms/artwork/new')
        }
      });
    } 
  }

  React.useEffect(() => {
    getArtwork();
    getAssignableStories(); 
  }, []);

  const onSubmit = (data: ArtworkFormInput) => {
    artworkId === undefined 
      ? ArtworkAPI.create(data).then(() => navigate('/cms/artworks'))
      : ArtworkAPI.update(artworkId, data).then(() => navigate('/cms/artworks'))
  }
  
  React.useEffect(() => {
    if (selectedImage !== undefined) {
      setValue('artworkUrl', `${selectedImage}`);
    }
  }, [setValue, selectedImage]);

  const onUploadImage = (data: ImageUploadInput) => {
    ImageAPI.uploadImage(data).then((imageLink) => setSelectedImage(imageLink))
  }

  const storyChoices = [{label: 'Show and connect a Story', value: 'true'}, {label: 'No Story', value: 'false'}];
  const pageChoices = [{label: 'Recent Paintings', value: 'recent'}, {label: 'Archive', value: 'archive'}];


  React.useEffect(() => {
    setValue("storyId", selectedStory);
    let story = storyListChoices.find(({ id }) => id === selectedStory);

    if (story) {
      setSelectedStoryTitle(story.title.split("\n")[1]);
    } else {
    }
  }, [setValue, selectedStory, storyListChoices]);

  React.useEffect(() => {
    setValue("artworkUrl", selectedImage);
  }, [setValue, selectedImage]);

  // todo make this work with api
  const showStoryField = watch('hasStory');

  return (<div>
    <PageHeader title='Add New Artwork'>
      <TopBar>
      </TopBar>
    </PageHeader>
    <FormHolder>
      <div style={{display: 'flex'}}>
        <div style={{width: '665px'}}>
          <FormTextField name='titleCn' label='Chinese Title' inputWidthPercent='80%' height='40px' control={register('titleCn')}/>
          <FormTextField name='titleEN' label='English Title' inputWidthPercent='80%' height='40px' control={register('titleEn')}/>
          <Controller
            name='hasStory'
            control={control}
            render={({field}) => {
              // exclude ref as it cant be handled downstream
              const {ref, ...fieldToPass} = field
              return <RadioFieldHorizontal label='Story' {...fieldToPass} choices={storyChoices} inputWidthPercent='80%'/>
            }}
          />
          { showStoryField === 'true' && <SelectModal label='' displayText={selectedStoryTitle} name='storyIdRadio' inputWidthPercent='80%' action={setSelectedStory} modalComponent={
              <RadioFieldVertical label='' name='storyIdRadio' choices={storyListChoices.map(choice => <StoryChoiceListRow {...choice}/>)} defaultValue={selectedStory} inputWidthPercent='100%'/>
            }/>
          }
          <Controller
            name='createdAt'
            control={control}
            defaultValue={new Date().toISOString().slice(0, 10)}
            render={({ field }) => {
              return (<FormDatePicker field={field} inputWidthPercent={'80%'}/>)
            }} 
          />
          <FormTextField name='widht' label='Width, cm' inputWidthPercent='80%' height='40px' control={{inputMode: 'numeric', ...register('width', {valueAsNumber: true})}}/>
          <FormTextField name='height' label='Height, cm' inputWidthPercent='80%' height='40px' control={{inputMode: 'numeric', ...register('height', {valueAsNumber: true})}}/>
          <FormTextField name='description' label='Description' inputWidthPercent='80%' height='40px' control={register('description')}/>
          <Controller
            name='isSold'
            control={control}
            defaultValue={false}
            render={({ field }) => {
              return (<FormCheckboxField field={field} name='isSold' label='Artwork sold' inputWidthPercent='80%'/>)
            }} 
          />
          <Controller
            name='page'
            control={control}
            render={({field}) => {
              // exclude ref as it cant be handled downstream
              const {ref, ...fieldToPass} = field
              return <RadioFieldHorizontal label='Page Attribution' {...fieldToPass} choices={pageChoices} defaultValue={'recent'} inputWidthPercent='80%'/>
            }}
          /> 
        </div>
        <div style={{marginLeft: '90px', width: '462px', height: '462px'}}>
          <Controller
            name='artworkUrl'
            control={control}
            render={({field}) => {
              return<ImageUploadField field={field} label={''} uploadAction={onUploadImage} inputWidthPercent={'100%'}/>
            }}
          />
        </div>
      </div>
      <Stack spacing={2} direction='row' sx={{marginTop: '60px'}}>
        <ActionButton label='Publish' onClick={handleSubmit(onSubmit)}/>
        <CancelButton label='Reset' onClick={() => reset()}/>
      </Stack>
    </FormHolder>
  </div>)
}