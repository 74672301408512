import * as React from 'react';
import { StoryAPI } from '../api/StoryAPI';
import RouterButton from '../components/Form/RouterButton';
import PageHeader from '../components/Layout/PageHeader';
import TopBar from '../components/Layout/TopBar';
import List from '../components/List/List';
import { ListConfig, ReorderRequestInput, StoryListRowData } from '../types';

const listConfig: ListConfig = {
  columnConfig: [    
    {
      columnKey: 'id',
      columnName: 'Reorder',
      columnWidth: '6%',
      sortable: false,
      type: 'reorder',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'coverImageUrl',
      columnName: 'Image',
      columnWidth: '9%',
      sortable: false,
      type: 'image',
      style: {
        'width': '100%',
        'height': 'auto',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'title',
      columnName: 'Title',
      columnWidth: '61%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'alignItems': 'center',
        'whiteSpace': 'pre-line',
        'lineHeight': '22px',
        'marginLeft': '15px'
      }
    },
    {
      columnKey: 'date',
      columnName: 'Created At',
      columnWidth: '12%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'id',
      columnName: 'Edit',
      columnWidth: '6%',
      sortable: false,
      type: 'edit',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'visible',
      columnName: 'Show',
      columnWidth: '6%',
      sortable: false,
      type: 'hide',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'id',
      columnName: 'Delete',
      columnWidth: '6%',
      sortable: false,
      type: 'delete',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
  ],
  smallList: false,
  showHeader: true,
  keyColumnKey: 'id',
}

const Stories = () => {
  const [storiesList , setStoriesList] = React.useState<StoryListRowData[]>([]);
  const stateRef = React.useRef(storiesList);
  // slice here is needed to copy this list to ensure that the list is reloaded after deletion
  stateRef.current = storiesList.slice();

  // get logic
  async function getStoryList() {
    await StoryAPI.getAll()
    .then((response) => setStoriesList(response))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }
  React.useEffect(() => {
    getStoryList();
  }, []);

  // delete logic
  async function deleteStory(idToRemove: string) {
    await StoryAPI.delete(idToRemove)
    .then(() => removeStoryFromList(idToRemove))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }

  const removeStoryFromList = (idToRemove: string) => {
    const idxToRemove = stateRef.current.findIndex(story => {
      return story.id === idToRemove;
    });
    stateRef.current.splice(idxToRemove, 1)
    setStoriesList(stateRef.current)
  }

  // hide logic
  async function setVisible(idToUpdate: string, newVisibleValue: boolean) {
    await StoryAPI.setVisible(idToUpdate, newVisibleValue)
    .then(() => updateStoryInList(idToUpdate, newVisibleValue))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }

  // reorder logic
  async function reorder(reorderRequest: ReorderRequestInput) {
    await StoryAPI.reorder(reorderRequest)
    .then(() => getStoryList())
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }
  
  const updateStoryInList = (idToUpdate: string, visibleValue: boolean) => {
    const idxToUpdate = stateRef.current.findIndex(story => {
      return story.id === idToUpdate;
    });
    stateRef.current[idxToUpdate].visible = visibleValue
    setStoriesList(stateRef.current)
  }


  return (<div>
    <PageHeader title='Stories'>
      <TopBar>
        <RouterButton label='+ Add New' to='new'/>
      </TopBar>
    </PageHeader>
    <List config={listConfig} data={storiesList} deleteAction={deleteStory} hideAction={setVisible} reorderAction={reorder}/>
  </div>)
}

export default Stories