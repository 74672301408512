import { FormControlLabel, TextField } from '@mui/material'
import React from 'react'
import { FormTextFieldProps } from '../../types'

const FormTextField = (props: FormTextFieldProps) => {
  return (
  <FormControlLabel 
    labelPlacement='start' 
    label={props.label} 
    sx={{
      marginBottom: '24px', 
      display: 'flex', 
      justifyContent: 'space-between', 
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Nunito Sans', 
        fontSize: 12,
        lineHeight: '22px',
        letterSpacing: '1px'
    }}} 
    control={
      <TextField 
        inputProps={props.control}
        multiline={props.rows ? true: false}
        rows={props.rows}
        sx={{
          width: props.inputWidthPercent,
          '& .MuiOutlinedInput-input': {
            fontFamily: 'Nunito Sans', 
            fontSize: 12, 
            height: '7px',
            lineHeight: '22px',
            letterSpacing: '1px',
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": { 
              borderColor: '#2c2c2c',
              borderRadius: 1
            },
          }
        }} 
        id={props.name} 
        type='text'
      />}
  />)
}

export default FormTextField