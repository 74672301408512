import { MenuFormInput } from '../types';
import { api } from "./axiosConfigs";

export const MenuAPI = {
  get: async function (): Promise<MenuFormInput> {
    const response = await api.request({
      url: `/menu`,
      method: "GET",
    });
    return response.data;
  },
  update: async function (menu: MenuFormInput): Promise<MenuFormInput> {
    const response = await api.request({
      url: `/menu`,
      method: "PUT",
      data: menu,
    });
    return response.data;
  },
};
