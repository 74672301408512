import React from 'react';
import { Divider, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItemProps } from '../../types';

const drawerWidth = 285;

const MenuDrawer = (props) => <Drawer
  sx={{
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      backgroundColor: '#303030',
      boxSizing: 'border-box',
      color: '#FFFFFF',
    },
    '& .MuiListItemText-primary': {
      fontWeight: '600',
      marginLeft: '40px',
    }
  }}
  variant='permanent'
  anchor='left'
  >
    {props.children}
  </Drawer>

const MenuDivider = (props) => <Divider sx = {{
  backgroundColor: '#5A5A5A'}}/>

const MenuItem = (props: MenuItemProps) => <ListItem button key={props.order} sx = {{
  padding: '18px 0 18px 0'}} component={RouterLink} to={props.to}> {props.children} </ListItem>  

const TopMenuItem = (props: MenuItemProps) => <ListItem button key={props.order} component='a' href={props.to} sx = {{
  padding: '40px 0 40px 0'}}> {props.children} </ListItem>  

const Sidebar = () => {
  const { t } = useTranslation('common');
  return (
  <MenuDrawer>
    <List sx = {{ padding: 0}}>
      <TopMenuItem order={0} to='https://www.mi-wang.art'>
        <ListItemText primary={'Mi-Wang.art'} />
      </TopMenuItem>
      <MenuDivider />
      <MenuItem order={1} to='/'>
        <ListItemText primary={t('sidebar.dashboard')} />
      </MenuItem>
      <MenuDivider />
      <MenuItem order={2} to='/cms/menu'>
        <ListItemText primary={t('sidebar.menu')} />
      </MenuItem>
      <MenuDivider />
      <MenuItem order={3} to='/cms/artworks'>
        <ListItemText primary={t('sidebar.art')} />
      </MenuItem>
      <MenuDivider />
      <MenuItem order={4} to='/cms/stories'>
        <ListItemText primary={t('sidebar.stories')} />
      </MenuItem>
      <MenuDivider />
      <MenuItem order={5} to='/cms/about'>
        <ListItemText primary={t('sidebar.about')} />
      </MenuItem>
      <MenuDivider />
    </List>
  </MenuDrawer>)
}

export default Sidebar