import * as React from 'react';
import { useLocation } from "react-router";
import RouterButton from '../components/Form/RouterButton';
import ToggleRouterButton from '../components/Form/ToggleRouterButton';
import PageHeader from '../components/Layout/PageHeader';
import TopBar from '../components/Layout/TopBar';
import List from '../components/List/List';
import { ArtworkListRowData, ListConfig, ReorderRequestInput } from '../types';
import { ArtworkAPI } from '../api/ArtworkAPI';

const standardConfig: ListConfig = {
  columnConfig: [
    {
      columnKey: 'artworkUrl',
      columnName: 'Image',
      columnWidth: '9%',
      sortable: false,
      type: 'image',
      style: {
        'width': '100%',
        'height': 'auto',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'title',
      columnName: 'Title',
      columnWidth: '43%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'alignItems': 'center',
        'whiteSpace': 'pre-line',
        'lineHeight': '22px',
        'marginLeft': '15px'
      }
    },
    {
      columnKey: 'page',
      columnName: 'Page Assignment',
      columnWidth: '12%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'hasStory',
      columnName: 'Has Story',
      columnWidth: '12%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'createdAt',
      columnName: 'Created At',
      columnWidth: '12%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'id',
      columnName: 'Edit',
      columnWidth: '6%',
      sortable: false,
      type: 'edit',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'visible',
      columnName: 'Show',
      columnWidth: '6%',
      sortable: false,
      type: 'hide',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'id',
      columnName: 'Delete',
      columnWidth: '6%',
      sortable: false,
      type: 'delete',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
  ],
  smallList: false,
  showHeader: true,
  keyColumnKey: 'id'
}

const sortableConfig: ListConfig = {
  columnConfig: [
    {
      columnKey: 'id',
      columnName: 'Reorder',
      columnWidth: '6%',
      sortable: false,
      type: 'reorder',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'artworkUrl',
      columnName: 'Image',
      columnWidth: '9%',
      sortable: false,
      type: 'image',
      style: {
        'width': '100%',
        'height': 'auto',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'title',
      columnName: 'Title',
      columnWidth: '49%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'alignItems': 'center',
        'whiteSpace': 'pre-line',
        'lineHeight': '22px',
        'marginLeft': '15px'
      }
    },
    {
      columnKey: 'hasStory',
      columnName: 'Has Story',
      columnWidth: '12%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'createdAt',
      columnName: 'Created At',
      columnWidth: '12%',
      sortable: false,
      type: 'text',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'id',
      columnName: 'Edit',
      columnWidth: '6%',
      sortable: false,
      type: 'edit',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'visible',
      columnName: 'Show',
      columnWidth: '6%',
      sortable: false,
      type: 'hide',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
    {
      columnKey: 'id',
      columnName: 'Delete',
      columnWidth: '6%',
      sortable: false,
      type: 'delete',
      style: {
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }
    },
  ],
  smallList: false,
  showHeader: true,
  keyColumnKey: 'id'
}

const pageType = {
  ALL_ARTWORK_PAGE: '/cms/artworks/all',
  RECENT_ARTWORK_PAGE: '/cms/artworks/recent',
  ARCHIVED_ARTWORK_PAGE: '/cms/artworks/archive', 
}

const Art = () => {
  const [artworkList , setArtworkList] = React.useState<ArtworkListRowData[]>([]);
  const stateRef = React.useRef(artworkList);
  // slice here is needed to copy this list to ensure that the list is reloaded after deletion
  stateRef.current = artworkList.slice();

  // get current page
  var currentRoute = useLocation().pathname
  // get list type
  const listType = currentRoute.split('/').pop() || 'all'

  // get logic
  async function getArtworkList() {
    await ArtworkAPI.getAll(listType || 'all')
    .then((response) => setArtworkList(response))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }
  React.useEffect(() => {
    getArtworkList();
  }, []);

  // delete logic
  async function deleteArtwork(idToRemove: string) {
    await ArtworkAPI.delete(idToRemove)
    .then(() => removeArtworkFromList(idToRemove))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }

  const removeArtworkFromList = (idToRemove: string) => {
    const idxToRemove = stateRef.current.findIndex(story => {
      return story.id === idToRemove;
    });
    stateRef.current.splice(idxToRemove, 1)
    setArtworkList(stateRef.current)
  }

  // hide logic
  async function setVisible(idToUpdate: string, newVisibleValue: boolean) {
    await ArtworkAPI.setVisible(idToUpdate, newVisibleValue)
    .then(() => updateArtworkInList(idToUpdate, newVisibleValue))
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }

  // reorder logic
  async function reorder(reorderRequest: ReorderRequestInput) {
    await ArtworkAPI.reorder(reorderRequest, listType)
    .then(() => getArtworkList())
    .catch((e) => {
      //todo error handling
      console.log(e);
    });
  }

  const updateArtworkInList = (idToUpdate: string, visibleValue: boolean) => {
    const idxToUpdate = stateRef.current.findIndex(artwork => {
      return artwork.id === idToUpdate;
    });
    stateRef.current[idxToUpdate].visible = visibleValue
    setArtworkList(stateRef.current)
  }

  return (<div>
    <PageHeader title='Art'>
      <TopBar>
        <div style={{marginRight: '55px', backgroundColor: '#FFFFFF'}}>
          <ToggleRouterButton label='All' to={pageType.ALL_ARTWORK_PAGE} disabled={currentRoute !== pageType.ALL_ARTWORK_PAGE}/>
          <ToggleRouterButton label='Recent Paintings' to={pageType.RECENT_ARTWORK_PAGE} disabled={currentRoute !== pageType.RECENT_ARTWORK_PAGE}/>
          <ToggleRouterButton label='Archived' to={pageType.ARCHIVED_ARTWORK_PAGE} disabled={currentRoute !== pageType.ARCHIVED_ARTWORK_PAGE}/>
        </div>
        <RouterButton label='+ Add New' to='new'/>
      </TopBar>
    </PageHeader>
    <List config={['recent', 'archive'].includes(listType) ? sortableConfig : standardConfig} data={artworkList} deleteAction={deleteArtwork} hideAction={setVisible} reorderAction={reorder}/>
  </div>)
}

export default Art