const englishMessages: any= {
    dashboard: {
        pvChart: {
            pvLabel: 'PV',
            upvLabel: 'Unique PV',
            dailyLabel: 'By Day',
            weeklyLabel: 'By Week'
        }
    },
    sidebar: {
      about: 'Personal Info',
      art: 'Art Works',
      dashboard: 'Dashboard',
      menu: 'Menu',
      stories: 'Stories'
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
        },
    },
};

export default englishMessages;