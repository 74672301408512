import React, { useRef }  from 'react';
import { Card, CardContent } from '@mui/material'
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    LineChart,
    Line,
    Tooltip,
} from 'recharts';

import { useTranslation } from 'react-i18next';

import { PvCount } from '../../types';
import Subtitle from '../Layout/Subtitle';


const PvChart = (props: { pvData?: PvCount[], daily?: boolean }) => {
    const { pvData, daily } = props;
    const chartRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipValueRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation('common');

    const tooltipMouseOver = (dot) => {
        if (chartRef.current && tooltipRef.current && tooltipValueRef.current) {
            tooltipValueRef.current.innerHTML = dot.value;

            const chartHeight = chartRef.current.getBoundingClientRect().height;

            const tooltipBR = tooltipRef.current.getBoundingClientRect();

            tooltipRef.current.style.opacity = '1';
            tooltipRef.current.style.transform = `translate(${dot.cx-Math.floor(tooltipBR.width / 2)}px, ${-chartHeight + dot.cy - tooltipBR.height}px)`;
        }
      };
    
      const tooltipMouseOut = () => {
        if (tooltipRef.current) {
            tooltipRef.current.style.opacity = '0';
        }
      };
    

    if (!pvData) return null;

    return (
      <Card
        variant='outlined'
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          pading: 0
        }}>
        <Subtitle title={t(`dashboard.pvChart.${daily ? 'daily' : 'weekly'}Label`)} />
        <CardContent sx={{padding: 0}}>
            <div ref={chartRef} style={{ width: '100%', height: '300px' }}>
                <ResponsiveContainer>
                    <LineChart data={pvData}>
                        <XAxis
                            dataKey='label'
                            name='time'
                            type='category'
                            axisLine
                        />
                        <YAxis dataKey='pv' name='PV' unit='' axisLine={false}/>
                        <CartesianGrid strokeDasharray='2 2' vertical={false}/>
                        <Legend 
                            verticalAlign='top' 
                            align='right' 
                            iconType='plainline' 
                            formatter={(value) => <span style={{color: '#000000'}}>{value}</span>}
                            payload={[
                                {value: t('dashboard.pvChart.pvLabel'), type: 'plainline', color: '#624FFF', id: 'pv', payload: {strokeDasharray: '0'}},
                                {value: t('dashboard.pvChart.upvLabel'), type: 'plainline', color: '#EC398F', id: 'upv', payload: {strokeDasharray: '0'}}]}
                        />
                        <Tooltip cursor={false} wrapperStyle={{ display: 'none' }} />
                        <Line
                            type='linear'
                            dataKey='pv'
                            stroke='#624FFF'
                            isAnimationActive={false}
                            strokeWidth={2}
                            dot={FilledDot}
                            activeDot={{
                                onMouseOver: (e, dot) => tooltipMouseOver(dot),
                                onMouseLeave: () => tooltipMouseOut()
                            }}
                        />
                        <Line
                            type='linear'
                            dataKey='upv'
                            stroke='#EC398F'
                            strokeWidth={2}
                            dot={FilledDot}
                            isAnimationActive={false}
                            activeDot={{
                                onMouseOver: (e, dot) => tooltipMouseOver(dot),
                                onMouseLeave: () => tooltipMouseOut()
                            }}
                        />
                    </LineChart>
                </ResponsiveContainer>
                {/* Tooltip for value hover. Placed here for ref access */}
                <div ref={tooltipRef} style={{ marginTop: '-6px', position: 'absolute', width: 'fit-content', border: '1px solid', borderRadius: '6px', borderColor: '#D8D8D8', backgroundColor: 'white', padding: '4px', transition: 'none', opacity: 0 }}>
                    <div ref={tooltipValueRef}/>
                </div>
            </div>
        </CardContent>
      </Card>
    );
};


// draws a dot with a unique key/id
const FilledDot = (props) => {
    const { key, dataKey, cx, cy, stroke} = props;

    return (<svg id={`${dataKey}-${key}`} key={key} x={cx-3} y={cy-3} width='6' height='6' viewBox='0 0 6 6' fill={stroke} xmlns='http://www.w3.org/2000/svg'>
            <circle cx='3' cy='3' r='3' fill={stroke}/>
            </svg>);
}

export default PvChart;