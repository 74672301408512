import React from 'react';
import { styled } from '@mui/system';

const Container = styled('div')({
  height: '90vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const Spinner = styled('div')({
  '@keyframes pulse': {
    '0%, 100%': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    },
    '50%': {
      WebkitFilter: 'blur(21px)',
      filter: 'blur(15px)'
    }
  },
  height: '50px',
  width: '50px',
  borderRadius: '50%',
  backgroundColor: '#EC644B',
  animation: 'pulse 2s infinite ease-in-out',
})

export const PageLoader: React.FC = () => {
  return (
    <Container >
      <Spinner/>
    </Container>
  );
};