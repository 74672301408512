import { styled } from '@mui/system';
import { CardHeader } from '@mui/material';
import * as React from 'react';

const StyledHolder = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

const PageHeader = (props) => (<StyledHolder>
    <CardHeader 
      title = {props.title}
      sx={{  
        width: 'fit-content',
        padding: 0,
        marginBottom: '35px',
        '& .MuiCardHeader-title':  {
          fontWeight: 600, 
          fontSize: '24px', 
          color: '#000', 
          fontStyle: 'normal',
          fontFamily: 'Nunito Sans', 
          lineHeight: '22px',
          letterSpacing: '1px'
        }
      }}>
    </CardHeader>
    {props.children} 
  </StyledHolder>)

  export default PageHeader