import React, {useCallback, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import Placeholder from './Placeholder';
import ImagePreview from './ImagePreview';
import { ImageUploadFieldProps } from '../../../types';

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: '-webkit-fill-available',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ImageUploadField = (props: ImageUploadFieldProps) => {
  const onDrop = useCallback(acceptedFiles => {
    // todo add upload logic here
    console.log(acceptedFiles);
    if (acceptedFiles.length === 1) {
      const uploadedImage = props.uploadAction({'file': acceptedFiles[0]})
      if (uploadedImage !== '') {}
    }
  }, [props]);

  
  const {
    getRootProps,
    getInputProps,  
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({accept: {'image/*': []}, multiple: false, onDrop});
  const [showHoverOverlay, setHoverOverlay] = useState(false);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div {...getRootProps({style})} onMouseEnter={() => setHoverOverlay(true)} onMouseLeave={() => setHoverOverlay(false)}>
      <input {...getInputProps()}/>
      {props.field.value !== undefined
        ? (<ImagePreview imagefile={`${props.field.value}?width=290`} showHoverOverlay={showHoverOverlay} />) 
        : (<Placeholder/>)
      }
    </div>
  );
}

export default ImageUploadField