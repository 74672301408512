import * as React from 'react';

import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material';

import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import ProtectedRoute from './components/Layout/ProtectedRoute';

// auth
import { Auth0Context, Auth0Provider } from '@auth0/auth0-react';

// i18n
import englishMessages from './i18n/en';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

// pages
import Sidebar from './components/Layout/Sidebar';
import Dashboard from './pages/Dashboard';
import Menu from './pages/Menu';
import Art from './pages/Art';
import ArtworkForm from './pages/ArtworkForm';
import Stories from './pages/Stories';
import About from './pages/About';
import chineseMessages from './i18n/cn';
import StoryForm from './pages/StoryForm';
import LoginPage from './pages/Login';

  const theme = createTheme({
      palette: {
        primary: {
          main: '#000',
        },
        secondary: {
          main: '#fff',
        },
      },
      typography: {
        fontFamily: 'Nunito Sans',
        fontSize: 12,
        fontWeightBold: 700,
        fontWeightRegular: 400,
      },
      overrides: {
        MuiFormControlLabel: {
          label: {
            fontFamily: 'Nunito Sans',
            letterSpacing: 1,
            fontSize: 12,
            fontWeightBold: 700,
            fontWeightRegular: 400,
          }
        }
      }
    });

    i18next.init({
      interpolation: { escapeValue: false },
      lng: 'en',
      resources: {
        en: {
          common: englishMessages
        },
        cn: {
          common: chineseMessages
        }
      }
  }); 

  const Holder = styled('div')({
    display: 'flex'
  })

  const Content = styled('div')({
    flexGrow: 1,
    margin: '45px 35px 45px 55px',
    letterSpacing: 1
  })

  const App = () => (
    <Auth0Provider
      domain='dev-6ljnbkzl.us.auth0.com'
      clientId='nm3kc9bxAHuQkb6JK4yVUaJ1nww5476w'
      audience='https://dev-6ljnbkzl.us.auth0.com/api/v2/'
      redirectUri={window.location.origin}
    >
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently }) => {
          getAccessTokenSilently().then((token) => {
            localStorage.setItem('accessToken', token);
          }
        );
        
        return (
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
              <Holder>
              <Sidebar/> 
              <Content>
                <Routes>
                  <Route path='cms/menu' element={<ProtectedRoute component={Menu} />}/>
                  <Route path='cms/artworks' element={<Navigate replace to='all' />}/>
                  <Route path='cms/artworks/all' element={<ProtectedRoute component={Art} />}/>
                  <Route path='cms/artworks/all/new' element={<Navigate replace to='/cms/artworks/new' />}/>
                  <Route path='cms/artworks/all/:artworkId' element={<ProtectedRoute component={ArtworkForm} />}/>
                  <Route path='cms/artworks/:artworkId' element={<ProtectedRoute component={ArtworkForm} />}/>
                  <Route path='cms/artworks/recent' element={<ProtectedRoute component={Art} />}/>
                  <Route path='cms/artworks/recent/new' element={<Navigate replace to='/cms/artworks/new' />}/>
                  <Route path='cms/artworks/recent/:artworkId' element={<ProtectedRoute component={ArtworkForm} />}/>
                  <Route path='cms/artworks/archive' element={<ProtectedRoute component={Art} />}/>
                  <Route path='cms/artworks/archive/new' element={<Navigate replace to='/cms/artworks/new' />}/>
                  <Route path='cms/artworks/archive/:artworkId' element={<ProtectedRoute component={ArtworkForm} />}/>
                  <Route path='cms/artworks/new' element={<ProtectedRoute component={ArtworkForm} />}/>
                  <Route path='cms/artworks/:artworkId' element={<ProtectedRoute component={ArtworkForm} />}/>
                  <Route path='cms/stories' element={<ProtectedRoute component={Stories} />}/>
                  <Route path='cms/stories/new' element={<ProtectedRoute component={StoryForm} />}/>
                  <Route path='cms/stories/:storyId' element={<ProtectedRoute component={StoryForm} />}/>
                  <Route path='cms/about' element={<ProtectedRoute component={About} />}/>
                  <Route path='/' element={<ProtectedRoute component={Dashboard} />}/>
                  <Route path='/oauth' element={<LoginPage />} />
                </Routes>
              </Content>
            </Holder>
          </I18nextProvider>
        </ThemeProvider>
      )}}
    </Auth0Context.Consumer>
  </Auth0Provider>
);
export default App;
