import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps';

import { geoPatterson } from 'd3-geo-projection';

const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries-sans-antarctica.json';

// const markers = [
//   {
//     markerOffset: -30,
//     name: "Buenos Aires",
//     coordinates: [-58.3816, -34.6037]
//   },
//   { markerOffset: 15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
//   { markerOffset: 15, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
//   { markerOffset: 15, name: "Santiago", coordinates: [-70.6693, -33.4489] },
//   { markerOffset: 15, name: "Bogota", coordinates: [-74.0721, 4.711] },
//   { markerOffset: 15, name: "Quito", coordinates: [-78.4678, -0.1807] },
//   { markerOffset: -30, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
//   { markerOffset: -30, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
//   { markerOffset: 15, name: "Paramaribo", coordinates: [-55.2038, 5.852] },
//   { markerOffset: 15, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
//   { markerOffset: 15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
//   { markerOffset: 15, name: "Lima", coordinates: [-77.0428, -12.0464] }
// ];

const projection = geoPatterson()
  .scale(98);

const WorldChart = () => {
  return (
    <ComposableMap 
      projection={projection} 
      projectionConfig={{ scale: 1000 }}
      // width={580}
      viewBox={'150 0 680 450'}
      // height={951}
      style={{
        width: '100%',
        height: 'auto',
        marginTop: '20px'
      }}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} fill='#D9D9D9' stroke='#D9D9D9' style={{
            default: { outline: 'none' },
            hover: { outline: 'none' },
            pressed: { outline: 'none' },
          }} />)
        }
      </Geographies>
        <Marker key={0} coordinates={[23.903597, 54.898521]}>
            <circle cx='0' cy='0' r='3' stroke='#624FFF' fill='#624FFF' />
        </Marker>
    </ComposableMap>
  );
};

export default WorldChart;