import * as React from 'react'
import { styled } from '@mui/system';

const StyledTopBar = styled('div')({
  display: 'flex'
})

const TopBar = (props) => (<StyledTopBar>
    {props.children} 
  </StyledTopBar>)

  export default TopBar