import { FormControlLabel, TextField, Box, Modal, Stack, Typography, InputAdornment, IconButton } from '@mui/material'
import React from 'react'
import { SelectModalProps } from '../../types'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionButton from './ActionButton';
import CancelButton from './CancelButton';


const SelectModal = (props: SelectModalProps) => {
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // modal controls
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
  <div>
    <FormControlLabel 
      labelPlacement='start' 
      label={props.label} 
      sx={{
        cursor: 'pointer',
        marginBottom: '24px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        '& .MuiFormControlLabel-label': {
          fontFamily: 'Nunito Sans', 
          fontSize: 12,
          lineHeight: '22px',
          letterSpacing: '1px'
      }}} 
      control={
        <TextField 
          onClick={() => handleOpen()}
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="open sory select"
                  onClick={() => handleOpen()}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  edge="end"
                  sx={{
                    color: '#000'
                  }}
                >
                  <MoreHorizIcon/>
                </IconButton>
              </InputAdornment>
          }}
          inputProps={props.control}
          multiline={false}
          sx={{
            width: props.inputWidthPercent,
            '& .MuiOutlinedInput-input': {
              fontFamily: 'Nunito Sans', 
              fontSize: 12, height: '7px',
              lineHeight: '22px',
              letterSpacing: '1px',
              cursor: 'pointer'
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": { 
                borderColor: '#2c2c2c',
                borderRadius: 1
              },
            }
          }} 
          id={props.name} 
          type='text'
          value={props.displayText}
        />}
    />
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select a Story
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {props.modalComponent}
          <Stack spacing={2} direction='row' sx={{marginTop: '60px'}}>
            <ActionButton label='Select' onClick={() => {
                props.action((document.querySelector(`[name="${props.name}"]:checked`) as HTMLInputElement)?.value);
                handleClose();
              }}/>
            <CancelButton label='Cancel' onClick={() => handleClose()}/>
          </Stack>
        </Typography>
      </Box>
    </Modal>
  </div>)
}

export default SelectModal