import { Button } from '@mui/material'
import React from 'react'
import { CancelButtonProps } from '../../types'

const CancelButton = (props: CancelButtonProps) => {
  const styles = {
    color: props.disabled ? '#D8D8D8' : '#303030',
    borderColor: props.disabled ? '#D8D8D8' : '#303030',
    backgroundColor: '#FFF',
    width: '152px',
    textTransform: 'none',
    letterSpacing: 1,
    ':hover': {
      ':hover': {
        color: '#585858',
        borderColor: '#585858',
        backgroundColor: '#FFF',
      },
    }
  }
  return (<Button sx={styles} variant='outlined' onClick={props.onClick} disabled={props.disabled}>{props.label}</Button>)
}

export default CancelButton