import { FormControlLabel, RadioGroup } from '@mui/material'
import React from 'react'
import { RadioFieldVerticalProps } from '../../types'

const RadioFieldVertical = (props: RadioFieldVerticalProps) => {
  const {inputWidthPercent, ...radioGroupProps} = props
  return (
      <FormControlLabel 
        labelPlacement='start' 
        label={radioGroupProps.label} 
        sx={{
          marginBottom: '24px', 
          display: 'flex', 
          justifyContent: 'space-between', 
          '& .MuiFormControlLabel-label': {
            fontFamily: 'Nunito Sans', 
            fontSize: 12,
            lineHeight: '22px',
            letterSpacing: '1px'
        }}} 
        control={
          <RadioGroup {...radioGroupProps} 
            sx={{
              width: props.inputWidthPercent
            }}
          >
            {props.choices}
          </RadioGroup>
        }
        />
  )
}

export default RadioFieldVertical