import React from 'react'
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';

const HideCol = (props: any) => {
  const { rowId, action, state } = props;
  var Toggle = ToggleOffOutlinedIcon
  if (state === true) {
    Toggle = ToggleOnOutlinedIcon
  }
  if (action === undefined) {
    return <Toggle onClick={() => alert('hide action has not been defined for this list')}/>
  }
  return <Toggle onClick={() => action(rowId, !state)}/>
}

export default HideCol