import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { RadioFieldProps } from '../../types'

const RadioFieldHorizontal = (props: RadioFieldProps) => {
  const buildChoice = (label: string, value: string) => {
    return <FormControlLabel sx={{marginLeft: 0}} key={value} value={value} label={label} control={
      <Radio
        sx={{
          color: '#000000', 
          '&.Mui-checked': {
            color: '#000000',
          },
        }}
      />}
    />
  }

  const {inputWidthPercent, ...radioGroupProps} = props
  return (
      <FormControlLabel 
        labelPlacement='start' 
        label={radioGroupProps.label} 
        sx={{
          marginBottom: '24px', 
          display: 'flex', 
          justifyContent: 'space-between', 
          '& .MuiFormControlLabel-label': {
            fontFamily: 'Nunito Sans', 
            fontSize: 12,
            lineHeight: '22px',
            letterSpacing: '1px'
        }}} 
        control={
          <RadioGroup {...radioGroupProps} row 
            sx={{
              width: props.inputWidthPercent
            }}
          >
            {props.choices.map(choice => buildChoice(choice.label, choice.value))}
          </RadioGroup>
        }
        />
  )
}

export default RadioFieldHorizontal