import { Button } from '@mui/material'
import React from 'react'
import { ActionButtonProps } from '../../types'

const ActionButton = (props: ActionButtonProps) => {
  const styles = {
    backgroundColor: props.disabled ? '#D8D8D8' : '#303030',
    color: '#FFF',
    width: '152px',
    height: '40px',
    textTransform: 'none',
    letterSpacing: 1,
    ':hover': {
      backgroundColor: '#585858',
      color: 'white',
    },
  }

  return (<Button sx={styles} variant='text' onClick={props.onClick} disabled={props.disabled}>{props.label}</Button>)
}

export default ActionButton