import { AboutFormInput } from '../types';
import { api } from './axiosConfigs';

export const PersonalInfoAPI = {
  get: async function (): Promise<AboutFormInput> {
    const response = await api.request({
      url: `/personal-info`,
      method: "GET",
    });
    return response.data;
  },
  update: async function (
    personalInfo: AboutFormInput
  ): Promise<AboutFormInput> {
    const response = await api.request({
      url: `/personal-info`,
      method: "PUT",
      data: personalInfo,
    });
    return response.data;
  },
};
