import React from 'react';

const SelectAnotherOverlay = () => (
  <div style={{position: 'absolute'}}>
    <div><img style={{display: 'block', margin: 'auto', color: '#FFF'}} src={'/imageUploadWhite.svg'} alt={'upload prompt'}/></div>
    <p style={{textAlign: 'center', fontSize: '24px', marginTop: '14px', marginBottom: '0px', color: '#FFF'}}>Select Another Image</p>
    <p style={{textAlign: 'center', margin: 0, color: '#FFF'}}>Image size should be under 3MB</p>
  </div>
);

export default SelectAnotherOverlay;