import update from 'immutability-helper';
import React from 'react';
import { ListProps } from '../../types';
import { styled } from '@mui/system';
import ListRow from './ListRow';
import SortingIcon from './SortingIcon';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const StyledFlexTable = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: "Nunito Sans",
  fontSize: 12
})

const StyledFlexHeader = styled('div')({
  display: 'flex',
  background: '#F2F2F2',
  textAlign: 'center'
})

const List = (props: ListProps) => {
  const { config, data, deleteAction, hideAction, reorderAction } = props; 
  const [rows, setRows] = React.useState(data);
  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const columns = config.columnConfig.map((column) => {
    return {
      key: column.columnKey,
      component: styled("div")({
        flex: 1,
        flexBasis: column.columnWidth,
        borderStyle: config.showHeader ? "none solid none solid" : "none",
        borderColor: "#fff",
      }),
      header: column.columnName,
      sortable: column.sortable,
      type: column.type,
      style: column.style,
    };
  });

  const moveRow = React.useCallback((dragIndex: number, hoverIndex: number) => {
    setRows((oldRows) => {
      oldRows[dragIndex]["order"] = oldRows[hoverIndex]["order"];
      for (var i = hoverIndex; i < oldRows.length; i++) {
        oldRows[i]["order"] += 1;
      }

      return update(oldRows, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, oldRows[dragIndex]],
        ],
      });
    });
  }, []);
  const dropRow = React.useCallback(
    (insertedAt: number) => {
      if (reorderAction === undefined) {
        return;
      }

      setRows((oldRows) => {
        //todo performance issue :)
        reorderAction({
          reorderedList: oldRows.map((row, index) => ({
            id: row.id,
            order: index,
          })),
          dragId: oldRows[insertedAt]["id"],
          insertAtId: oldRows[insertedAt]["id"],
        });
        return oldRows;
      });
    },
    [reorderAction]
  );

  const renderRow = React.useCallback(
    (row: any, index: number) => {
      return (
        <ListRow
          small={config.smallList}
          key={row[config.keyColumnKey]}
          index={index}
          moveRow={moveRow}
          dropRow={dropRow}
          columns={columns}
          row={row}
          keyColumnName={row[config.keyColumnKey]}
          deleteAction={deleteAction}
          hideAction={hideAction}
        />
      );
    },
    [
      config.keyColumnKey,
      config.smallList,
      deleteAction,
      dropRow,
      hideAction,
      moveRow,
    ]
  );
  return (
    <StyledFlexTable>
      {config.showHeader && (
        <StyledFlexHeader>
          {columns.map((col) => {
            const Col = col.component;
            return (
              <Col
                key={col.key + col.type}
                style={{
                  fontSize: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "fit-content" }}>
                  {col.header}
                  {col.sortable && <SortingIcon />}
                </div>
              </Col>
            );
          })}
        </StyledFlexHeader>
      )}
      <DndProvider backend={HTML5Backend}>
        {rows.map((row, idx) => renderRow(row, idx))}
      </DndProvider>
    </StyledFlexTable>
  );
}

export default List;