import * as React from 'react'
import { CardHeader } from '@mui/material'

const Subtitle = (props) => (<CardHeader 
  title = {props.title}
  sx={{  
    padding: 0,
    marginBottom: '35px',
    '& .MuiCardHeader-title':  {
      fontWeight: 600, 
      fontSize: '16px', 
      color: '#000', 
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '1px'
    }
  }}/>)

  export default Subtitle