import { ImageUploadInput } from '../types';
import { api } from "./axiosConfigs";

export const ImageAPI = {
  uploadImage: async function (image: ImageUploadInput): Promise<string> {
    let formData = new FormData();
    formData.append("files[0]", image.file);
    const response = await api.request({
      url: `/upload-image`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response);
    return response.data.data.imageLink;
  },
};
