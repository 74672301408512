import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from "./PageLoader";

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const Component = withAuthenticationRequired(props.component, {
    onRedirecting: () => (
      <div className='page-layout'>
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};

export default ProtectedRoute;