import * as React from 'react';
import ActionButton from '../components/Form/ActionButton';
import FormTextField from '../components/Form/FormTextField';
import PageHeader from '../components/Layout/PageHeader';
import { useForm } from "react-hook-form";
import { MenuFormInput } from '../types';
import CancelButton from '../components/Form/CancelButton';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';
import { MenuAPI } from '../api/MenuAPI';

const FormHolder = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: "Nunito Sans",
  fontSize: 12
})

// todo replace with api
const defaultValues = {
  page1: "Recent Paintings",
  page2: "Archived",
  page3: "Stories",
  page4: "CV&Contact",
};

const Menu = () => {
  const [initialFormData, setInitialFormData] =
    React.useState<MenuFormInput>(defaultValues);
  async function getMenu() {
    setInitialFormData(await MenuAPI.get());
  }

  const {
    register,
    handleSubmit,
    reset
  } = useForm<MenuFormInput>({defaultValues: initialFormData});

  React.useEffect(() => {
    getMenu();
  }, []);

  React.useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]); 

  const onSubmit = (data: MenuFormInput) => {
    MenuAPI.update(data).then(() => getMenu())
  }
  
  return (<FormHolder>
    <PageHeader title='Menu'/>
    <div style={{width: '565px'}}>
      <FormTextField name='page1' label='Page 1' inputWidthPercent='80%' height='40px' control={register('page1')}/>
      <FormTextField name='page2' label='Page 2' inputWidthPercent='80%' height='40px' control={register('page2')}/>
      <FormTextField name='page3' label='Page 3' inputWidthPercent='80%' height='40px' control={register('page3')}/>
      <FormTextField name='page4' label='Page 4' inputWidthPercent='80%' height='40px' control={register('page4')}/>
    </div>
    <Stack spacing={2} direction="row" sx={{marginTop: '60px'}}>
      <ActionButton label='Publish' onClick={handleSubmit(onSubmit)}/>
      <CancelButton label='Reset' onClick={() => reset()}/>
    </Stack>
  </FormHolder>)
}

export default Menu