import React, { useRef, useMemo } from 'react';
import { FormControlLabel } from '@mui/material'
import JoditEditor from 'jodit-react';

const RichTextField = ({ placeholder, field, inputWidthPercent, label }) => {
	const editor = useRef(null);
	const [content, setContent] = useMemo(() => field.value, [field.value]);

	const config = useMemo(() => (
		{
			readonly: false, // all options from https://xdsoft.net/jodit/doc/,
			placeholder: placeholder || 'Start typing...',
      enableDragAndDropFileToEditor: true,
      format: 'json',
			uploader: {
				// insertImageAsBase64URI: true
        url: `${process.env.REACT_APP_BASE_URL}/upload-image`,
        fileUploadField: 'files',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}` 
        },
        isSuccess: function (resp) {
          return resp;
        },
        process: function (resp) { 

            console.log(JSON.stringify(resp));
            return {
                files: [resp.data.resourceUrl],
                path: resp.data.imageLink,
                baseurl: 'https://abefnjxjgo.cloudimg.io/v7/_my_/',
                error: 'error should go here',
                message: 'message should go nere'
            }
        },
        defaultHandlerSuccess: function (data) {
            var i, field = 'files'; 
            
            // "this" is different when using drag&drop and toolbar
            var selection = this.selection === undefined ? this.jodit.selection : this.selection;                   
            if (data[field] && data[field].length) {
                for (i = 0; i < data[field].length; i += 1) {
                    selection.insertImage(data.baseurl + data[field][i]);
                }
            }
        },
			},
		}),
		[placeholder]
	);

	return (
    <FormControlLabel 
        labelPlacement='start' 
        label={label} 
        sx={{
          marginBottom: '24px', 
          display: 'flex', 
          justifyContent: 'space-between', 
          '& .MuiFormControlLabel-label': {
            fontFamily: 'Nunito Sans', 
            fontSize: 12,
            lineHeight: '22px',
            letterSpacing: '1px'
        }}} 
      control={
      <div style={{width: inputWidthPercent}}>
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          tabIndex={1} // tabIndex of textarea
          onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={field.onChange}
          {...field}
          />
        </div>
      }
		/>
	);
};

export default RichTextField;