import React from 'react';
import SelectAnotherOverlay from './SelectAnotherOverlay';

const ImagePreview = ({ imagefile, showHoverOverlay }) => {
  const name = ''
  return (
    <div key={name} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      {showHoverOverlay && <SelectAnotherOverlay/>}
      <div style={{ backgroundImage: `url(${imagefile})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '100%', height: '100%' }}>
      </div>
    </div>
  );
}

export default ImagePreview;