import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';


const EditCol = ({ to }) => {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate(to), [navigate, to]);

  return <ModeEditOutlineOutlinedIcon onClick={handleOnClick} />
}

export default EditCol