import {
  ArtworkFormInput,
  ArtworkListRowData,
  ReorderRequestInput,
} from '../types';
import { api } from './axiosConfigs';

export const ArtworkAPI = {
  getOne: async function (artworkId: string): Promise<ArtworkFormInput> {
    const response = await api.request({
      url: `/artwork/${artworkId}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async function (page: string): Promise<ArtworkListRowData[]> {
    const response = await api.request({
      url: `/artworks/${page}`,
      method: "GET",
    });
    return response.data;
  },
  create: async function (
    artwork: ArtworkFormInput
  ): Promise<ArtworkFormInput> {
    const response = await api.request({
      url: `/artwork`,
      method: "POST",
      data: artwork,
    });
    return response.data;
  },
  update: async function (
    artworkId: string,
    artwork: ArtworkFormInput
  ): Promise<ArtworkFormInput> {
    const response = await api.request({
      url: `/artwork/${artworkId}`,
      method: "PUT",
      data: artwork,
    });
    return response.data;
  },
  delete: async function (artworkId: string): Promise<any> {
    const response = await api.request({
      url: `/artwork/${artworkId}`,
      method: "DELETE",
    });
    return response;
  },
  setVisible: async function (
    artworkId: string,
    visibilityStatus: boolean
  ): Promise<any> {
    const response = await api.request({
      url: `/artwork/${artworkId}/set-visible/${visibilityStatus}`,
      method: "PUT",
    });
    return response;
  },
  reorder: async function (
    reorderRequest: ReorderRequestInput,
    listType: string
  ): Promise<any> {
    const response = await api.request({
      url: `/artworks/reorder`,
      params: {
        listType: listType,
      },
      method: "PUT",
      data: reorderRequest,
    });
    return response;
  },
};
