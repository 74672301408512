import { Box } from '@mui/material';
import React from 'react';

const SortingIcon = (props) => {
  return (
    <div style={{width: '14 px', float: 'right', margin: 'auto', display: 'flex', flexDirection: 'column', marginLeft: '5px'}}>
      <Box
        component="img"
        sx={{
          height: 6,
          width: 7,
          maxHeight: { xs: 54, md: 72 },
          maxWidth: { xs: 54, md: 72 },

        }}
        alt="thumb"
        src={'/sortAsc.svg'}/>
      <Box
        component="img"
        sx={{
          height: 6,
          width: 7,
          maxHeight: { xs: 54, md: 72 },
          maxWidth: { xs: 54, md: 72 },

        }}
        alt="thumb"
        src={'/sortDesc.svg'}/>
    </div>
  )
}

export default SortingIcon