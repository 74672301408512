const chineseMessages: any = {
    dashboard: {
        pvChart: {
            pvLabel: 'PV',
            upvLabel: '独特 PV',
            dailyLabel: '日每',
            weeklyLabel: '每周'
        }
    },
    sidebar: {
      about: '个人信息',
      art: '艺术品',
      dashboard: '仪表盘',
      menu: '菜单',
      stories: '故事'
    },
};

export default chineseMessages;