import * as React from 'react'
import { Card, Grid } from '@mui/material'
import VisitorList from '../components/Dashboard/VisitorList';
import PvChart from '../components/Dashboard/PvChart';
import WorldChart from '../components/Dashboard/WorldChart';
import ArtworkList from '../components/Dashboard/ArtworkList';
import PageHeader from '../components/Layout/PageHeader';
import DatePicker from '../components/Form/DatePicker';
import TopBar from '../components/Layout/TopBar';
import { DashboardArtworkListRowData, PvCount } from "../types";
import { DashboardAPI } from "../api/dashboardApis";

export default () => {
  const [dailyVisits, setDailyVisits] = React.useState<PvCount[]>([]);
  const [weeklyVisits, setWeeklyVisits] = React.useState<PvCount[]>([]);
  const [artworkVisits, setArtworkVisits] = React.useState<
    DashboardArtworkListRowData[]
  >([]);
  React.useEffect(() => {
    getDailyVisits();
    getWeeklyVisits();
    getArtworkVisits();
  }, []);

  async function getDailyVisits() {
    await DashboardAPI.getDailyVisits()
      .then((response) => {
        setDailyVisits(response);
      })
      .catch((e) => {
        //todo error handling
        console.log(e);
      });
  }

  async function getWeeklyVisits() {
    await DashboardAPI.getWeeklyVisits()
      .then((response) => {
        setWeeklyVisits(response);
      })
      .catch((e) => {
        //todo error handling
        console.log(e);
      });
  }

  async function getArtworkVisits() {
    await DashboardAPI.getArtworkVisits()
      .then((response) => {
        setArtworkVisits(response);
      })
      .catch((e) => {
        //todo error handling
        console.log(e);
      });
  }

  return (
    <div>
      <PageHeader title="Dashboard">
        <TopBar>
          {/* disable for now */}
          {/* <DatePicker /> */}
        </TopBar>
      </PageHeader>
      <Grid container spacing={4} sx={{ marginBottom: "25px" }}>
        {/* Daily views */}
        <Grid item xs={12} md={12} lg={6}>
          <PvChart pvData={dailyVisits} daily />
        </Grid>
        {/* Weekly views */}
        <Grid item xs={12} md={12} lg={6}>
          <PvChart pvData={weeklyVisits} />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: "25px" }}>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={6}>
              <VisitorList />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <WorldChart />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container sx={{ marginBottom: "25px" }}>
        <Card
          variant="outlined"
          sx={{
            width: "100%",
          }}
        >
          <Grid sx={{ margin: "25px" }}>
            <ArtworkList visitData={artworkVisits} />
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};