import React from 'react';

const Placeholder = () => (
  <div style={{margin: 'auto'}}>
    <div><img style={{display: 'block', margin: 'auto'}} src={'/imageUpload.svg'} alt={'upload prompt'}/></div>
    <p style={{textAlign: 'center', fontSize: '24px', marginTop: '14px', marginBottom: '0px'}}>Upload Image</p>
    <p style={{textAlign: 'center', margin: 0}}>Image size should be under 3MB</p>
  </div>
);

export default Placeholder;