import * as React from 'react';
import List from './../List/List';
import { ListConfig, VisitorListRowData } from '../../types';
import Subtitle from '../Layout/Subtitle';

var mockVs: VisitorListRowData[] = [
    {
        'flag': 'US',
        'country': 'United States',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'LT',
        'country': 'Lithuania',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'RU',
        'country': 'Russia',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'LV',
        'country': 'Latvia',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'UA',
        'country': 'Ukraine',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'FR',
        'country': 'France',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'CN',
        'country': 'China',
        'views': 5000,
        'percentage': '10.5%'
    },
    {
        'flag': 'JP',
        'country': 'Japan',
        'views': 5000,
        'percentage': '10.5%'
    },
]

const listConfig: ListConfig = {
  columnConfig: [
    {
      columnKey: 'flag',
      columnName: 'Flag',
      columnWidth: '8%',
      sortable: false,
      type: 'flag',
      style: {}
    },
    {
      columnKey: 'country',
      columnName: 'Country',
      columnWidth: '62%',
      sortable: false,
      type: 'text',
      style: {}
    },
    {
      columnKey: 'views',
      columnName: 'Views',
      columnWidth: '19%',
      sortable: false,
      type: 'text',
      style: {}
    },
    {
      columnKey: 'percentage',
      columnName: 'Percentage',
      columnWidth: '11%',
      sortable: false,
      type: 'text',
      style: {}
    }
  ],
  smallList: true,
  showHeader: false,
  keyColumnKey: 'country'
}


const VisitorList = () => (
  <div style={{margin: '15px 240px 15px 35px'}}>
    <Subtitle title={'Visits by Location'} />
    <List config={listConfig} data={mockVs}/>
  </div>
);

export default VisitorList;