
import { DashboardArtworkListRowData, PvCount } from '../types';
import { api } from './axiosConfigs';

export const DashboardAPI = {
  get: async function () {
    const response = await api.request({
      url: `/overview`,
      method: "GET",
    });
    return response.data.product;
  },
  getDailyVisits: async function (): Promise<PvCount[]> {
    const response = await api.request({
      url: `/daily-visits`,
      method: "GET",
    });
    return response.data;
  },
  getWeeklyVisits: async function (): Promise<PvCount[]> {
    const response = await api.request({
      url: `/weekly-visits`,
      method: "GET",
    });
    return response.data;
  },
  getArtworkVisits: async function (): Promise<DashboardArtworkListRowData[]> {
    const response = await api.request({
      url: `/artwork-visits`,
      method: "GET",
    });
    return response.data;
  },
};