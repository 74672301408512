import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { FormCheckboxFieldProps } from '../../types'

const FormCheckboxField = (props: FormCheckboxFieldProps) => {
  const {onChange, value, ...rest} = props.field;
  return (
  <FormControlLabel 
    labelPlacement='start' 
    label={props.label} 
    sx={{
      marginBottom: '24px', 
      display: 'flex', 
      justifyContent: 'space-between', 
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Nunito Sans', 
        fontSize: 12,
        lineHeight: '22px',
        letterSpacing: '1px'
    }}} 
    control={
      <div style={{width: props.inputWidthPercent}}>
        <Checkbox 
          inputProps={props.control}
          id={props.name} 
          checked={value}
          onChange={onChange}
          {...rest}
        />
      </div>
    }
  />)
}

export default FormCheckboxField