import {
  ReorderRequestInput,
  StoryFormInput,
  StoryListRowData,
  StoryListDropdownData,
} from '../types';
import { api } from './axiosConfigs';

export const StoryAPI = {
  getOne: async function (storyId: string): Promise<StoryFormInput> {
    const response = await api.request({
      url: `/story/${storyId}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async function (): Promise<StoryListRowData[]> {
    const response = await api.request({
      url: `/stories`,
      method: "GET",
    });
    return response.data;
  },
  getAssignable: async function (): Promise<StoryListDropdownData[]> {
    const response = await api.request({
      url: `/stories/assignable`,
      method: "GET",
    });
    return response.data;
  },
  create: async function (story: StoryFormInput): Promise<StoryFormInput> {
    const response = await api.request({
      url: `/story`,
      method: "POST",
      data: story,
    });
    return response.data;
  },
  update: async function (
    storyId: string,
    story: StoryFormInput
  ): Promise<StoryFormInput> {
    const response = await api.request({
      url: `/story/${storyId}`,
      method: "PUT",
      data: story,
    });
    return response.data;
  },
  delete: async function (storyId: string): Promise<any> {
    const response = await api.request({
      url: `/story/${storyId}`,
      method: "DELETE",
    });
    return response;
  },
  setVisible: async function (
    storyId: string,
    visibilityStatus: boolean
  ): Promise<any> {
    const response = await api.request({
      url: `/story/${storyId}/set-visible/${visibilityStatus}`,
      method: "PUT",
    });
    return response;
  },
  reorder: async function (reorderRequest: ReorderRequestInput): Promise<any> {
    const response = await api.request({
      url: `/stories/reorder`,
      method: "PUT",
      data: reorderRequest,
    });
    return response;
  },
};
