import * as React from 'react';
import List from '../List/List';
import Subtitle from '../Layout/Subtitle';
import { DashboardAPI } from '../../api/dashboardApis';
import { DashboardArtworkListRowData, ListConfig } from '../../types';

const listConfig: ListConfig = {
  columnConfig: [
    {
      columnKey: 'thumbnail',
      columnName: 'Image',
      columnWidth: '9%',
      sortable: false,
      type: 'image',
      style: {
        'textAlign': 'center'
      }
    },
    {
      columnKey: 'title',
      columnName: 'Title',
      columnWidth: '43%',
      sortable: false,
      type: 'text',
      style: {
        'textAlign': 'center'
      }
    },
    {
      columnKey: 'pvDay',
      columnName: 'PV by Day',
      columnWidth: '12%',
      sortable: true,
      type: 'text',
      style: {
        'textAlign': 'center'
      }
    },
    {
      columnKey: 'upvDay',
      columnName: 'Unique PV by Day',
      columnWidth: '12%',
      sortable: true,
      type: 'text',
      style: {
        'textAlign': 'center'
      }
    },
    {
      columnKey: 'pvWeek',
      columnName: 'PV by Week',
      columnWidth: '12%',
      sortable: true,
      type: 'text',
      style: {
        'textAlign': 'center'
      }
    },
    {
      columnKey: 'upvWeek',
      columnName: 'Unique PV by Week',
      columnWidth: '12%',
      sortable: true,
      type: 'text',
      style: {
        'textAlign': 'center'
      }
    },
  ],
  smallList: false,
  showHeader: true,
  keyColumnKey: 'id'
}

var asd = DashboardAPI.get();

const ArtworkList = (props: { visitData: DashboardArtworkListRowData[] }) => (
  <div>
    <Subtitle title={"Visits by Artwork"} />
    <List config={listConfig} data={props.visitData} />
  </div>
);

export default ArtworkList;