import { FormControlLabel, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import React from 'react'
import { FormDatePickerProps } from '../../types'

const FormDatePicker = (props: FormDatePickerProps) => {
  const styles = {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: '#D8D8D8',
    color: '#FFF',
    width: '152px',
      textTransform: 'none',
    letterSpacing: 1,
    padding: '0px 15px 0px 15px',
    '& .MuiInput-root': {
      fontFamily: 'Nunito Sans',
      fontWeight: '600',
      ':before': {
        borderBottom: 'none !important',
      },
      ':after': {
        borderBottom: 'none !important',
        transition: 'none !important',
      },
    },
    
  }

  const {onChange, ...rest} = props.field;
  const handleChange = (newValue: Date | null) => {
    onChange(newValue!.toISOString().slice(0, 10));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>

        <FormControlLabel 
          labelPlacement='start' 
          label='Date' 
          sx={{
            marginBottom: '24px', 
            display: 'flex', 
            justifyContent: 'space-between', 
            '& .MuiFormControlLabel-label': {
              fontFamily: 'Nunito Sans', 
              fontSize: 12,
              lineHeight: '22px',
              letterSpacing: '1px'
          }}} 
          control={
            <div style={{width: props.inputWidthPercent}}>
              <DesktopDatePicker
                mask='____-__-__'
                inputFormat="yyyy-MM-dd"
                onChange={handleChange} 
                renderInput={(params) => <TextField {...params} sx={styles} variant='standard' disabled={props.disabled}/>}    
                components={{
                  OpenPickerIcon: ArrowDropDownIcon
                }}
                {...rest}
              />
           </div>
          }/>
    </LocalizationProvider>)
}

export default FormDatePicker