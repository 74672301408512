import * as React from 'react';
import PageHeader from '../components/Layout/PageHeader';
import { styled } from '@mui/system';
import ActionButton from '../components/Form/ActionButton';
import FormTextField from '../components/Form/FormTextField';
import { useForm } from 'react-hook-form';
import { AboutFormInput } from '../types';
import CancelButton from '../components/Form/CancelButton';
import { Stack } from '@mui/material';
import { PersonalInfoAPI } from '../api/PersonalInfo';

const FormHolder = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Nunito Sans',
  fontSize: 12
})

const About = () => {
  const [initialFormData , setInitialFormData] = React.useState<AboutFormInput | undefined>(undefined);
  async function getAbout() {
    setInitialFormData(await PersonalInfoAPI.get());
  }

  const {
    register,
    handleSubmit,    
    reset
  } = useForm<AboutFormInput>({defaultValues: initialFormData});
 
  React.useEffect(() => {
    getAbout();
  }, []);

  React.useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]); 

  const onSubmit = (data: AboutFormInput) => {
    PersonalInfoAPI.update(data).then(() => getAbout())
  }
  
  return (<FormHolder>
    <PageHeader title='Personal Info'/>
    <div style={{width: '800px'}}>
      <FormTextField name='contact' label='Contact' inputWidthPercent='86%' control={register('contact')} rows={5}/>
      <FormTextField name='about' label='About' inputWidthPercent='86%' control={register('about')} rows={5}/>
      <FormTextField name='bio' label='Bio' inputWidthPercent='86%' control={register('bio')} rows={5}/>
      <FormTextField name='statement' label='Statement' inputWidthPercent='86%' control={register('statement')} rows={5}/>
      <FormTextField name='groupExhibitions' label='Group Exhibitions' inputWidthPercent='86%' control={register('groupExhibitions')} rows={15}/>
    </div>
    <Stack spacing={2} direction='row' sx={{marginTop: '60px'}}>
      <ActionButton label='Publish' onClick={handleSubmit(onSubmit)}/>
      <CancelButton label='Reset' onClick={() => reset()}/>
    </Stack>
  </FormHolder>)
}

export default About