import { Box, Radio, styled } from '@mui/material'
import React from 'react'
import { StoryListDropdownData } from '../../types'

const StyledFlexRow = styled('div')(() => ({
  display: 'flex',
  margin: '12px 0 12px 0'
}))

const StoryChoiceListRow = (props: StoryListDropdownData) => {
  return <StyledFlexRow>
    <Radio
      disableFocusRipple
      disableTouchRipple  
      disableRipple
      key={props.id}
      value={props.id}
      sx={{
        width: '84px',
        color: '#000000',
        '&.Mui-checked': {
          color: '#000000',
        },
      }} />
      <Box
        component="img"
        sx={{
          height: 72,
          width: 72,
          marginRight: '12px',
          maxHeight: { xs: 54, md: 72 },
          maxWidth: { xs: 54, md: 72 },

        }}
        alt="thumb"
        src={props.coverImageUrl}
      />
      <div style={{whiteSpace: 'pre-line', alignItems: 'center', display: 'flex', fontFamily: 'Nunito Sans', fontStyle: 'normal', fontWeight: 400, fontSize: '12px', lineHeight: '22px', letterSpacing: '1px'}}>
        {props.title}
      </div>
  </StyledFlexRow>
}

export default StoryChoiceListRow