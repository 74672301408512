import axios, { AxiosRequestConfig } from 'axios' 

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_BASE_URL,
})

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})


// Auth header injection point
api.interceptors.request.use(function (config: AxiosRequestConfig<any>): AxiosRequestConfig<any> {
  if (config.headers === undefined) {
    return config;
  }
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});