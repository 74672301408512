import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '../components/Form/ActionButton';
import PageHeader from '../components/Layout/PageHeader';
import TopBar from '../components/Layout/TopBar';
import { styled } from '@mui/system';
import FormTextField from '../components/Form/FormTextField';
import RichTextField from '../components/Form/RichTextField';
import { Controller, useForm } from "react-hook-form";
import { ImageUploadInput, StoryFormInput} from '../types';
import CancelButton from '../components/Form/CancelButton';
import { Stack } from '@mui/material';
import FormDatePicker from '../components/Form/FormDatePicker';
import ImageUploadField from '../components/Form/ImageUploadField/ImageUploadField';
import { ImageAPI } from '../api/ImageAPI';
import { StoryAPI } from '../api/StoryAPI';

const FormHolder = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: "Nunito Sans",
  fontSize: 12,
})

export default () => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue
  } = useForm<StoryFormInput>({defaultValues: {content: ''}});

  const [initialFormData , setInitialFormData] = React.useState<StoryFormInput | undefined>();
  
  React.useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]); 

  const { storyId } = useParams();

  const navigate = useNavigate();

  async function getStory() {
    if (storyId) {
      await StoryAPI.getOne(storyId)
      .then((response) => setInitialFormData(response))
      .catch((e) => {
        //todo error handling
        if (e.response.status === 404) {
          navigate('/cms/stories/new')
        }
      });
    } 
  }
  React.useEffect(() => {
    getStory();
  }, []);


  const onSubmit = (data: StoryFormInput) => {
    storyId === undefined 
      ? StoryAPI.create(data).then(() => navigate('/cms/stories'))
      : StoryAPI.update(storyId, data).then(() => navigate('/cms/stories'))
  }
  
  const [selectedImage, setSelectedImage] = React.useState<string | null>();
  React.useEffect(() => {
    if (selectedImage !== undefined) {
      setValue('coverImageUrl', `${selectedImage}`);
    }
  }, [setValue, selectedImage]);

  const onUploadImage = (data: ImageUploadInput) => {
    ImageAPI.uploadImage(data).then((imageLink) => setSelectedImage(imageLink))
  }

  return (<div>
    <PageHeader title='Add New Story'>
      <TopBar>
      </TopBar>
    </PageHeader>
    <FormHolder>
    <div style={{display: 'flex'}}>
      <div style={{width: '837px'}}>
        <FormTextField name='titleCn' label='Chinese Title' inputWidthPercent='80%' height='40px' control={register('titleCn')}/>
        <FormTextField name='titleEN' label='English Title' inputWidthPercent='80%' height='40px' control={register('titleEn')}/>   
        <Controller
          name="date"
          control={control}
          defaultValue={new Date().toISOString().slice(0, 10)}
          render={({ field }) => {
            return (<FormDatePicker field={field} inputWidthPercent={'80%'}/>)
          }} 
        /> 
        <Controller
          name="content"
          control={control}
          defaultValue={''}
          render={({ field }) => (<RichTextField field={field} label={'Content'} placeholder={'Story time'} inputWidthPercent={'80%'}/>)} 
        /> 
      </div>
      <div style={{marginLeft: '90px', width: '290px', height: '290px'}}>
          <Controller
            name='coverImageUrl'
            control={control}
            render={({field}) => {
              return<ImageUploadField field={field} label={''} uploadAction={onUploadImage} inputWidthPercent={'100%'}/>
            }}
          />
        </div>
    </div>
    <Stack spacing={2} direction="row" sx={{marginTop: '60px'}}>
      <ActionButton label='Publish' onClick={handleSubmit(onSubmit)}/>
      <CancelButton label='Reset' onClick={() => reset()}/>
    </Stack>
  </FormHolder>
  </div>)
}